require("@rails/ujs").start()
//require("@rails/activestorage").start()
//require("channels")
//require("trix")
//require("@rails/actiontext")

import $ from 'jquery'
import 'qtip2'
import 'cocoon-js'
import 'select2'
import 'select2/select2_locale_pt-BR'
import 'bootstrap'
import 'controllers'

import flatPickr from 'flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

import ActionCable from 'actioncable'
window.cable = ActionCable.createConsumer()

import NProgress from 'nprogress'
window.NProgress = NProgress

import Turbolinks from 'turbolinks'
Turbolinks.start()
Turbolinks.setProgressBarDelay(250)

import 'stylesheets/application'